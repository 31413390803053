<template>
  <div>
    <div class="row justify-content-end">
      <div class="col-md-10">
        <div class="hello-intro">
          <div class="row">
            <div class="col-lg-9 col-md-9">
              <h2 class="hello-header" data-aos="fade-up">404</h2>
              <p class="hello-sub">Page Not Found</p>
              <p
                class="hello-text"
                data-aos="fade-up"
                data-aos-delay="200"
              >Hey there! The page you are looking for wasn't found...</p>
            </div>
          </div>
        </div>

        <next-page path="/" title="Home" content="Go Back Home"></next-page>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.title,
    };
  },
  computed: {
    title() {
      return `Page not found - ${this.$config.name}`;
    },
  },
};
</script>

<style></style>
